import { ModuleWithProviders, NgModule } from "@angular/core";
import { DeloitteAiEnvironment, DELOITTEAI_ENVIRONMENT } from "./api";

@NgModule({
    imports: []
})
export class DeloitteAiEnvironmentModule {

    static forRoot(environment: DeloitteAiEnvironment): ModuleWithProviders<DeloitteAiEnvironmentModule> {

        return {
            ngModule: DeloitteAiEnvironmentModule,
            providers: [{
                provide: DELOITTEAI_ENVIRONMENT,
                useValue: environment
            }]
        };
    }
}
