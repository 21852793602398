import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "@environments/environment";
import { DELOITTEAI_ENVIRONMENT } from "@shared/environment";


if (environment.production) {
    enableProdMode();
}

function MyModuleFactory(): Promise<any> {
    const isMsIE = (window.document as any).documentMode;

    return !isNaN(isMsIE)
        ? import("@shared/page_internet_explorer/ie.module").then((module) => module.IEModule)
        : import("./app/app.module").then((module) => module.AppModule);
}

MyModuleFactory().then((module) => {
    platformBrowserDynamic(
        [{
            provide: DELOITTEAI_ENVIRONMENT,
            useValue: {
                brain_base_url: environment.brain_base_url,
                context: "store"
            }
        }]
    ).bootstrapModule(module).catch(err => console.log(err));
});
